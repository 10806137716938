/** @format */

import { addOrUpdateUserClaim } from "fetchers/userClaims";

const SET_CATEGORIES = "SET_CATEGORIES";
const SET_USER_INFO = "SET_USER_INFO";
const SET_FAVORITES = "SET_FAVORITES";
const ADD_FAVORITE = "ADD_FAVORITE";
const SET_SEEN_POPUP = "SET_SEEN_POPUP";


const initialState = {
  userInfo: [],
  categories: [],
  favorites: [],
  seen_popup: false,
};

export const setUserInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
      case SET_SEEN_POPUP:
      return {
        ...state,
        seen_popup: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_FAVORITES:
      return {
        ...state,
        favorites: action.payload || [],
      };
    case ADD_FAVORITE:
      if (state.favorites?.filter(x => x === action.payload).length > 0) {
        addOrUpdateUserClaim(
          state.userInfo.idUser,
          1, //favorite claim
          state.favorites.filter(x => x !== action.payload)
        );
        return {
          ...state,
          favorites: state.favorites.filter(x => x !== action.payload),
        };
      } else {
        addOrUpdateUserClaim(
          state.userInfo.idUser,
          1, //favorite claim
          [...state.favorites, action.payload]
        );
        return {
          ...state,
          favorites: [...state.favorites, action.payload],
        };
      }
    default:
      return state;
  }
};
