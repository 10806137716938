/** @format */

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Row,
  Col,
  Label,
  Badge,
  CardFooter,
  Table,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { graficoATortaVideocorsi } from "variables/charts";
import { CardOutputStrumento } from "components/StrumentiComponents/StrumentiComponents";
import { useSelector } from "react-redux";
import {
  okGoldMiner,
  okDax,
  okCurrencies,
  okOilExtractor,
} from "variables/activeTools";
import Select from "react-select";

import {
  greenLong,
  redShort,
} from "components/StrumentiComponents/StrumentiComponents";
import IrFilterData from "variables/irFilterData";
import { okIndex } from "variables/activeTools";
import { checkFromIdStrumento } from "variables/generalChecker";
import { checkFromIdStrumentoCurrencies } from "variables/generalChecker";
import { checkFromIdStrumentoIndexes } from "variables/generalChecker";
import { checkFromIdStrumentoCommodities } from "variables/generalChecker";
import { okCommodities } from "variables/activeTools";
import serviceCardImg from "assets/serviceCardImg/serviceCardImg";
import {
  moduloColInCardSm,
  moduloColInCardXs,
  moduloColInCardLg,
} from "components/Modules/ModuleComponents";
import CorrelationFilter from "variables/correlationFilter";
import { isLong } from "variables/generalChecker";
import CorrelationTab from "components/Tabs/CorrelationTabs";
import { useEffect, useState } from "react";
import { checkType } from "variables/generalChecker";
import { isASymbol } from "variables/generalChecker";
import { chartCorrelation } from "variables/charts";
import { DemoGauge } from "components/StrumentiComponents/StrumentiComponents";
import { routesFinancialTools } from "variables/generalChecker";
import zIndex from "@mui/material/styles/zIndex";
import { addOrUpdateUserVariable } from "fetchers/userVariables";
import moment from "moment";
import { Aforismi } from "variables/aforismi";
import Ticker, { FinancialTicker } from "nice-react-ticker";
import { responseGetUserVariablesByIdVar } from "fetchers/userVariables";
import { responseGetUserVariablesByIdUser } from "fetchers/userVariables";
import { calculateMode } from "variables/calc";
import BarChart from "variables/barChart";

export const CardWelcome = props => {
  return (
    <Card className="card-user">
      <CardHeader>
        <h5 className="card-category">BENVENUTO </h5>
        <CardTitle tag="h2">{props.userInfo.name}</CardTitle>
      </CardHeader>
    </Card>
  );
};

export const CardAforismi = props => {
  let num = parseInt(moment().format("L").split("/")[0]);
  if (num > 25) num = Math.floor(num % 25);
  return (
    <Card className="card-user">
      <CardHeader>
        <CardTitle
          tag="h2"
          style={{ fontStyle: "italic", fontSize: 20, textAlign: "center" }}
        >
          {Aforismi()[num]}
        </CardTitle>
      </CardHeader>
    </Card>
  );
};
export const CardTicker = props => {
  return (
    <div className="custom-ticker-container mb-4">
      <Ticker slideSpeed={200} className="custom-ticker " loop>
        {props.settings.filter(x=>x.idStrumento!=="BTCUSD").map(x => {
          const varAss = x.currentPrice - x.lastPrice;
          const varPerc = (varAss / x.lastPrice) * 100;
          return (
            <FinancialTicker
              className="ticker-item"
              key={x.idStrumento}
              id={x.idStrumento}
              change={x.currentPrice > x.lastPrice}
              symbol={x.idStrumento}
              lastPrice={x.lastPrice}
              percentage={varPerc.toFixed(2) + "%"}
              currentPrice={x.currentPrice}
            />
          );
        })}
      </Ticker>
    </div>
  );
};

export const CardRisorse = ({ risorse }) => {
  const user = useSelector(state => state.user);
  let groupedById = {};
  risorse.forEach(obj => {
    const { idProdotto } = obj;
    if (!groupedById[idProdotto]) {
      groupedById[idProdotto] = obj;
    } else {
      // Se c'è già un oggetto con lo stesso idProdotto, unisci le proprietà
      Object.assign(groupedById[idProdotto], obj);
    }
  });
  // Ottieni i valori dell'oggetto raggruppato per creare un array unificato
  let unifiedArray = Object.values(groupedById);

  return (
    <Card className="card-user">
      <CardHeader>
        <h5 className="card-category">PACCHETTI ATTIVI:</h5>
        <Badge />
        {unifiedArray
          .filter(x => x.idCategoria !== 32)
          ?.sort((x1, x2) => x1.titolo.localeCompare(x2.titolo))
          .map(e => (
            <Badge
              key={e.titolo + e.descrizioneCategoria}
              color="info"
              style={{
                fontSize: 12,
                padding: 5,
                margin: 1,
              }}
            >
              {e.titolo.replace(/[?"]/g, "")}
            </Badge>
          ))}

        {unifiedArray.filter(x => x.idCategoria === 32).length > 0 && (
          <h5 className="card-category mt-3">CONTENUTI EXTRA:</h5>
        )}
        <Badge />
        {unifiedArray
          .filter(x => x.idCategoria === 32)
          .sort((x1, x2) => x1.titolo.localeCompare(x2.titolo))
          ?.map(e => (
            <Badge
              key={e.titolo + e.descrizioneCategoria}
              style={{ fontSize: 11, padding: 4, paddingBottom: 2 }}
            >
              {e.titolo.replace(/[?"]/g, "")}
            </Badge>
          ))}
      </CardHeader>
      <CardFooter />
    </Card>
  );
};

export const CardBestCurrency = props => {
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const userData = useSelector(state => state.user);

  const outputFilterCurrencies = output?.strumenti
    ?.filter(x =>
      checkFromIdStrumentoCurrencies(x.idStrumento.toLowerCase(), userData)
    )
    ?.filter(
      x =>
        x.affidabilita > 0.5 &&
        x.filtroDirezione === x.confermaIngressoSbs &&
        ((x.filtroDirezione === "LONG" && x.esitoUlcerIndexW1 === "COMPRA") ||
          (x.filtroDirezione === "SHORT" && x.esitoUlcerIndexW1 === "VENDI"))
    );

  const bestCurrencie =
    outputFilterCurrencies.length > 0
      ? outputFilterCurrencies.sort(
          (x1, x2) => x2.affidabilita - x1.affidabilita
        )[0]
      : null;

  return bestCurrencie ? (
    CardOutputStrumento(
      bestCurrencie,
      true,
      "Currency",
      navigate,
      props?.settings,
      userData
    )
  ) : (
    <></>
  );
};
export const CardBestIndex = props => {
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const userData = useSelector(state => state.user);
  const outputFilterIndexes = output?.strumenti
    ?.filter(x =>
      checkFromIdStrumentoIndexes(x.idStrumento.toLowerCase(), userData)
    )
    ?.filter(
      x =>
        x.affidabilita > 0.5 &&
        x.filtroDirezione === x.confermaIngressoSbs &&
        ((x.filtroDirezione === "LONG" && x.esitoUlcerIndexW1 === "COMPRA") ||
          (x.filtroDirezione === "SHORT" && x.esitoUlcerIndexW1 === "VENDI"))
    );
  const bestIndex =
    outputFilterIndexes?.length > 0
      ? outputFilterIndexes.sort(
          (x1, x2) => x2.affidabilita - x1.affidabilita
        )[0]
      : null;

  return bestIndex ? (
    CardOutputStrumento(
      bestIndex,
      true,
      "Index",
      navigate,
      props?.settings,
      userData
    )
  ) : (
    <></>
  );
};
export const CardBestCommodity = props => {
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const userData = useSelector(state => state.user);
  const outputFilterCommodities = output?.strumenti
    ?.filter(x =>
      checkFromIdStrumentoCommodities(x.idStrumento.toLowerCase(), userData)
    )
    ?.filter(
      x =>
        x.affidabilita > 0.5 &&
        x.filtroDirezione === x.confermaIngressoSbs &&
        ((x.filtroDirezione === "LONG" && x.esitoUlcerIndexW1 === "COMPRA") ||
          (x.filtroDirezione === "SHORT" && x.esitoUlcerIndexW1 === "VENDI"))
    );
  const bestCommodity =
    outputFilterCommodities?.length > 0
      ? outputFilterCommodities.sort(
          (x1, x2) => x2.affidabilita - x1.affidabilita
        )[0]
      : null;
  return bestCommodity ? (
    CardOutputStrumento(
      bestCommodity,
      true,
      "Commodity",
      navigate,
      props?.settings,
      userData
    )
  ) : (
    <></>
  );
};

export const CardRiepilogo = props => {
  const userData = useSelector(state => state.user);
  const output = useSelector(state => state.output.strumenti);
  const outputH1 = useSelector(state => state.output.strumentiH1);
  const [currenciesArrayObj, setCurrenciesArrayObj] = useState([]);

  const outputFilter = output?.filter(x =>
    checkFromIdStrumento(x.idStrumento.toLowerCase(), userData)
  );
  const outputFilterH1 = outputH1
    ?.filter(x => checkFromIdStrumento(x.idStrumento.toLowerCase(), userData))
    .filter(x => x.hour === moment().hour());

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  useEffect(() => {
    const currenciesGrouped = groupBy(
      props.h1 ? outputFilterH1 : outputFilter,
      outputFilter => outputFilter.idStrumento.substring(3)
    );

    currenciesGrouped.forEach((values, key, map) => {
      currenciesArrayObj.length < 7 &&
        setCurrenciesArrayObj(prevGroup => {
          // return prevGroup.concat(values);
          return prevGroup.concat({ key, values });
        });
    });
  }, []);
  return outputFilter.length > 0 ? (
    <Card className="card-user">
      <CardHeader>
        <h1 className="card-category">RIEPILOGO DIREZIONI</h1>
        {okCurrencies(userData) && (
          <>
            <p className="mb-4">CURRENCIES</p>
            <div>
              {currenciesArrayObj.map(obj => {
                return (
                  <div key={obj.key}>
                    <Row>
                      {isASymbol(obj.key) && (
                        <Col
                          md="12"
                          sm="12"
                          className="mt-3"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 10,
                          }}
                        >
                          <h4 className="mt-2">{obj.key}</h4>
                          <Badge
                            style={{
                              color: "white",
                              fontSize: 12,
                              backgroundColor:
                                CorrelationFilter(obj.values, "CURRENCIES")
                                  .esito === "DA COMPRARE"
                                  ? greenLong
                                  : redShort,
                            }}
                          >
                            {CorrelationFilter(obj.values, "CURRENCIES").esito}
                          </Badge>
                          <br />
                          <Badge color="info" style={{ fontSize: 18 }}>
                            {(
                              CorrelationFilter(
                                obj.values.filter(
                                  x => x.idStrumento.substring(0, 3) != "XAU",
                                  "CURRENCIES"
                                )
                              ).coefficiente * 100
                            ).toFixed()}
                            %
                          </Badge>
                        </Col>
                      )}

                      <Col lg="12">
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "90%",
                          }}
                        >
                          {obj.values
                            .filter(
                              x => checkType(x.idStrumento) === "CURRENCIES"
                            )
                            .map(x => {
                              return moduloColInCardXs(
                                x.idStrumento,
                                x.filtroDirezione,
                                x.filtroDirezione === "LONG"
                                  ? greenLong
                                  : redShort
                              );
                            })}
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      {isASymbol(obj.key) && (
                        <Col
                          lg="11"
                          style={{
                            height: "auto",
                            padding: 10,
                            marginBottom: 10,
                            borderBottom: "2px solid white",
                            opacity: "20%",
                          }}
                        ></Col>
                      )}
                    </Row>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {okIndex(userData) && (
          <>
            <br />
            <p className="mb-4">INDEXES</p>
            <Row>
              <Col
                md="12"
                sm="12"
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <>
                  <Badge
                    style={{
                      color: "white",
                      backgroundColor:
                        CorrelationFilter(
                          outputFilter?.filter(x =>
                            checkFromIdStrumentoIndexes(
                              x.idStrumento.toLowerCase(),
                              userData
                            )
                          ),
                          "INDEXES"
                        ).esito === "DA COMPRARE"
                          ? greenLong
                          : redShort,
                    }}
                  >
                    {
                      CorrelationFilter(
                        outputFilter?.filter(x =>
                          checkFromIdStrumentoIndexes(
                            x.idStrumento.toLowerCase(),
                            userData
                          )
                        ),
                        "INDEXES"
                      ).esito
                    }
                  </Badge>
                  <br />
                  <Badge color="info" style={{ fontSize: 18 }}>
                    {CorrelationFilter(
                      outputFilter?.filter(x =>
                        checkFromIdStrumentoIndexes(
                          x.idStrumento.toLowerCase(),
                          userData
                        )
                      ),
                      "INDEXES"
                    ).coefficiente * 100}
                    %
                  </Badge>
                </>
              </Col>
              {outputFilter
                ?.filter(x =>
                  checkFromIdStrumentoIndexes(
                    x.idStrumento.toLowerCase(),
                    userData
                  )
                )
                .map(x => {
                  return moduloColInCardXs(
                    x.idStrumento,
                    x.filtroDirezione,
                    x.filtroDirezione === "LONG" ? greenLong : redShort
                  );
                })}
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col
                lg="11"
                style={{
                  height: "auto",
                  padding: 10,
                  marginBottom: 10,
                  borderBottom: "2px solid white",
                  opacity: "20%",
                }}
              ></Col>
            </Row>
          </>
        )}

        {okCommodities(userData) && (
          <>
            <br />
            <p className="mb-4">COMMODODITIES</p>
            <Row>
              <Col
                md="12"
                sm="12"
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <>
                  <Badge
                    style={{
                      color: "white",
                      backgroundColor:
                        CorrelationFilter(
                          outputFilter?.filter(x =>
                            checkFromIdStrumentoCommodities(
                              x.idStrumento.toLowerCase(),
                              userData
                            )
                          ),
                          "COMMODITIES"
                        ).esito === "DA COMPRARE"
                          ? greenLong
                          : redShort,
                    }}
                  >
                    {
                      CorrelationFilter(
                        outputFilter?.filter(x =>
                          checkFromIdStrumentoCommodities(
                            x.idStrumento.toLowerCase(),
                            userData
                          )
                        ),
                        "COMMODITIES"
                      ).esito
                    }
                  </Badge>
                  <br />
                  <Badge color="info" style={{ fontSize: 18 }}>
                    {CorrelationFilter(
                      outputFilter?.filter(x =>
                        checkFromIdStrumentoCommodities(
                          x.idStrumento.toLowerCase(),
                          userData
                        )
                      ),
                      "COMMODITIES"
                    ).coefficiente * 100}
                    %
                  </Badge>
                </>
              </Col>
              {outputFilter
                ?.filter(x =>
                  checkFromIdStrumentoCommodities(
                    x.idStrumento.toLowerCase(),
                    userData
                  )
                )
                .map(x => {
                  return moduloColInCardXs(
                    x.idStrumento,
                    x.filtroDirezione,
                    x.filtroDirezione === "INDEXES" ? greenLong : redShort
                  );
                })}
            </Row>
          </>
        )}
        <Row>
          {okCurrencies(userData) ||
          okIndex(userData) ||
          okCommodities(userData) ? (
            <></>
          ) : (
            outputFilter
              ?.filter(x =>
                checkFromIdStrumento(x.idStrumento.toLowerCase(), userData)
              )
              .map(x => {
                return moduloColInCardXs(
                  x.idStrumento,
                  x.filtroDirezione,
                  x.filtroDirezione === "LONG" ? greenLong : redShort
                );
              })
          )}
        </Row>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  ) : (
    <></>
  );
};

export const CardCorrelationFilter = props => {
  const [selected, setSelected] = useState(null);
  const [asset, setAsset] = useState(null);
  const [position, setPosition] = useState(true);
  const outputStrumenti = props.outputStrumenti.strumenti.filter(x =>
    checkFromIdStrumento(x.idStrumento.toLowerCase(), props.userData)
  );
  const res =
    asset === "INDEX"
      ? CorrelationFilter(selected, "INDEXES")
      : asset === "COMMODITIES"
      ? CorrelationFilter(selected, "COMMODITIES")
      : CorrelationFilter(selected, "CURRENCIES", position);
  // if (longOfXXXUSD.length > 2 && shortOfUSDXXX.length > 1) {
  //   return output.idStrumento.substring(3) === "USD" ? "LONG" : "SHORT";
  // } else {
  //   return output.idStrumento.substring(3) === "USD" ? "SHORT" : "LONG";
  // }
  return (
    <Row style={{ marginBottom: 20 }}>
      <Col lg="8">
        <Card style={{ height: "100%" }}>
          <CardHeader>
            <h4>RIEPILOGO ESITI</h4>
          </CardHeader>
          <CardBody>
            {CorrelationTab(
              outputStrumenti,
              setSelected,
              setAsset,
              setPosition
            )}
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
      </Col>
      <Col lg="4">
        <Card style={{ height: "100%" }}>
          <CardHeader>
            <CardTitle>CORRELATION FILTER™</CardTitle>
            <h3>{asset}</h3>
            {DemoGauge(
              res.esito,
              res.coefficiente,
              res.esito === "DA COMPRARE" ? greenLong : redShort
            )}
          </CardHeader>
        </Card>
      </Col>
    </Row>
  );
};

export const CardBoxDomande = props => {
  const [selected, setSelected] = useState([]);
  const [gloabalTools, setGlobalTools] = useState([]);
  const [votanti, setVotanti] = useState(0);
  // const [nView, setNView] = useState(5);
  useEffect(() => {
    async function getResponse() {
      const resUserVariables = await responseGetUserVariablesByIdUser(
        props.user.userInfo.idUser
      );
      if (resUserVariables?.length > 0) setSelected(resUserVariables[0].value);
      await updateData();
    }
    getResponse();
  }, []);

  async function updateData() {
    const resVariables = await responseGetUserVariablesByIdVar(1);
    if (resVariables?.length > 0) {
      let values = resVariables.reduce(
        (acc, item) => [...acc, ...item.value],
        []
      );

      //votanti
      const currentTime = moment();
      let value;

      switch (true) {
        case currentTime.isBetween(
          moment("06:00", "HH:mm"),
          moment("12:00", "HH:mm")
        ):
          value = 7.92; // Mattina
          break;
        case currentTime.isBetween(
          moment("12:00", "HH:mm"),
          moment("18:00", "HH:mm")
        ):
          value = 5.77; // Pomeriggio
          break;
        case currentTime.isBetween(
          moment("18:00", "HH:mm"),
          moment("24:00", "HH:mm")
        ) ||
          currentTime.isBetween(
            moment("00:00", "HH:mm"),
            moment("06:00", "HH:mm")
          ):
          value = 3.79; // Sera o notte
          break;
        default:
          value = 1; // Valore predefinito
          break;
      }
      const result = (
        resVariables.filter(x => x.idVar === 1).length * value
      ).toFixed();
      setVotanti(
        (resVariables.filter(x => x.idVar === 1).length * value).toFixed()
      );

      //moda
      const moda = await calculateMode(values);

      let arrayRes = [];
      let sum = 0;
      for (let key in moda) {
        sum += moda[key];
      }
      for (let key in moda) {
        arrayRes.push({ idStrumento: key, value: moda[key] / sum });
      }
      setGlobalTools(arrayRes);
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "red",
    }),
  };
  return (
    <div>
      <Card className="card-chart" style={{ height: 400 }}>
        <CardHeader>
          <h5 className="card-category">Box domande</h5>
          <CardTitle tag="h4">
            <i className="fa-solid fa-circle-question"></i>
            Quali sono gli strumenti finanziari di tuo interesse oggi?
          </CardTitle>
          <Select
            value={selected?.map(opt => {
              return {
                label: opt,
                value: opt,
              };
            })}
            isMulti
            name="colors"
            placeholder="Seleziona strumenti"
            onChange={async x => {
              setSelected(x.map(y => y.label));
              await addOrUpdateUserVariable(
                1,
                props.user.userInfo.idUser,
                x.filter(y => y.label !== "").map(z => z.label),
                moment().format("yyyy-MM-d")
              );
              await updateData();
            }}
            options={routesFinancialTools()
              .filter(
                x =>
                  checkFromIdStrumento(x.name, props.user) && x.name != "BTCUSD"
              )
              .map(opt => {
                return {
                  label: opt.name,
                  value: opt.name,
                };
              })}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
          />
        </CardHeader>
        <CardBody
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          {gloabalTools.length > 0 && (
            <BarChart
              votanti={votanti}
              data={gloabalTools
                .sort(function (a, b) {
                  return b.value - a.value;
                })
                .filter(x => x.idStrumento !== "")
                .slice(0, 5)}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export const CardLinkWhatsapp = () => {
  return (
    <Card>
      <a href="https://wa.me/3518152993" target="_blank" rel="noreferrer">
        <CardHeader>
          <CardTitle style={{ display: "flex", maxHeight: 100 }}>
            {serviceCardImg("roilottery", "whatsapp")}

            <div style={{ marginLeft: 40 }}>
              <h4>ROILottery™ è disponibile!</h4>
              <h6>
                Scrivici su Whatsapp per acquistare un biglietto della nostra
                lotteria!
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const CardLinkPar = props => {
  return (
    <Card>
      <a
        href="https://docs.google.com/spreadsheets/d/1crJvo_607vzUdRqBLZWW80y8ByQO-1CH7lfKMtL1d9M/edit#gid=0"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex", maxHeight: 100 }}>
            <img
              alt={"sheets"}
              src={require(`assets/img/sheets.png`)}
              width={"30%"}
              height={"50%"}
              style={{ alignSelf: "center" }}
            />

            <div style={{ marginLeft: 40 }}>
              <h4>File PAR™</h4>
              <h6>
                Gestisci le tue entrare e le tue spese con il nostro Piano di
                Accumulo e Risparmio!
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};
export const CardLibroExcel = () => {
  return (
    <Card>
      <a
        href="https://docs.google.com/document/d/1_auWv-aKTlG9_QG_lbc115DZbMhnUqORplY9BLWpvGo/edit"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt="Google Docs"
              src={require("assets/img/googledocs.png")}
              width={100}
              height={85}
            />
            <div style={{ marginLeft: 40 }}>
              <h4>Documenti per chi ha acquistato il prodotto "Libro Excel"</h4>
              <h6>Clicca per accedere al documento!</h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const CardAntonio = () => {
  return (
    <Card>
      <a
        href="https://wa.me/3492512766?text=Ciao%20Antonio,%20vorrei%20fissare%20una%20call%20con%20te%20per%20la%20mia%20consulenza%20fiscale%20per%20trading"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img
              alt="Antonio Garcea"
              src={require("assets/img/antonio.jpg")}
              width={250}
              height={250}
            />
            <div style={{ marginTop: 20 }}>
              <h4 style={{textAlign: "center"}}>Servizio di consulenza per trader</h4>
              <h6>Assistenza completa nell'analisi, elaborazione e presentazione della dichiarazione dei redditi, con particolare 
                attenzione alla gestione di plusvalenze e minusvalenze derivanti da conti di trading.</h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const CardLibroExcelVideocorsi = () => {
  return (
    <Card>
      <a
        href="https://drive.google.com/drive/u/1/folders/1d6eVfja1kETwgXih6xVFftne4U06R62L"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              style={{ marginLeft: 10 }}
              alt="Google Docs"
              src={require("assets/img/videocorsi.png")}
              width={80}
              height={80}
            />
            <div style={{ marginLeft: 40 }}>
              <h4>Videocorsi per chi ha acquistato il prodotto Libro Excel</h4>
              <h6>Clicca per accedere al drive!</h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const InterestRateIFrame = () => {
  return (
    <Card>
      <CardHeader style={{ display: "flex", justifyContent: "center" }}>
        <Row>
          <Col lg="1"></Col>
          <Col lg="4">
            <img
              title="Investing.com"
              alt="Investing.com"
              border="0"
              src="https://92f8049275b46d631f32-c598b43a8fdedd4f0b9230706bd7ad18.ssl.cf1.rackcdn.com/forexpros_en_logo.png"
            />
            <p
              style={{
                fontSize: 11,
                fontWeight: "bold",
              }}
            >
              Tassi di Interesse offerti da{" "}
              <a
                href="https://it.investing.com/"
                rel="nofollow"
                target="_blank"
                style={{
                  fontSize: 11,
                  color: "#06529D",
                  fontWeight: "bold",
                }}
                className="underline_link"
              >
                Investing.com Italia
              </a>
            </p>
          </Col>
          <Col lg="1"></Col>
          <Col lg="6">
            <iframe
              frameBorder={0}
              scrolling="no"
              height="82"
              width="231"
              allowtransparency="true"
              src="https://sslirates.investing.com/index.php?rows=2&bg1=FFFFFF&bg2=F1F5F8&text_color=333333&enable_border=show&border_color=333333&header_bg=000000&header_text=FFFFFF&force_lang=9"
            ></iframe>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  );
};

export const CardEsitiIrFilter = props => {
  const irDataThis = IrFilterData(
    props.dataIR,
    props.output.strumenti,
    "",
    true
  );
  const userData = useSelector(state => state.user);

  const output = useSelector(state => state.output);
  const navigate = useNavigate();
  const outputFilter = output?.strumenti
    .filter(x => (okOilExtractor(userData) ? x : x.idStrumento !== "CRUDEOIL"))
    .filter(x => (okGoldMiner(userData) ? x : x.idStrumento !== "XAUUSD"))
    .filter(x => (okDax(userData) ? x : x.idStrumento !== "DE30"))
    .filter(x => (okCurrencies(userData) ? x : x.idStrumento === ""));
  // .filter(x => (okIndex(userData) ? x : null));

  return outputFilter.length > "0" ? (
    <Card>
      <CardHeader>SEGNALI CONCORDANTI CON IR FILTER™</CardHeader>
      <CardBody>
        <Table className="tablesorter">
          <thead className="text-primary">
            <tr>
              <th className="text-center">Strumento</th>
              <th className="text-center">Direzione attuale</th>
              <th className="text-center">Target giornaliero</th>
              <th className="text-center">Indicazioni chiusura</th>
            </tr>
          </thead>
          <tbody>
            {irDataThis?.length > 0 ? (
              irDataThis.map(x => (
                <tr key={x.idStrumento}>
                  <td className="text-center">{x.idStrumento}</td>
                  <td className="text-center">{x.filtroDirezione}</td>
                  <td className="text-center">{x.target}</td>
                  <td className="text-center">{x.confermaDiUscita}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};

export const CardLinkFacebook = props => {
  const key = "path";

  const array = Array.from(
    new Set(
      props.categories
        .filter(
          x =>
            x.idProdotto != 239 &&
            x.risorse.filter(x => x.type === "Fcb").length > 0
        )
        .map(x => {
          return {
            descrizione: x.risorse.filter(x => x.type === "Fcb")[0].descrizione,
            path: x.risorse.filter(x => x.type === "Fcb")[0].path,
          };
        })
    )
  );

  const arrayUniqueByKey = [
    ...new Map(array.map(item => [item[key], item])).values(),
  ];

  return arrayUniqueByKey.map(x => {
    return (
      <Card key={x.path}>
        <a href={x.path} target="_blank" rel="noreferrer">
          <CardHeader>
            <CardTitle style={{ display: "flex" }}>
              {serviceCardImg(x.descrizione, "facebook")}

              <div style={{ marginLeft: 40 }}>
                <h4>{x.descrizione}™</h4>
                <h6>Per Supporto, Dirette e Contenuti Esclusivi</h6>
              </div>
            </CardTitle>
          </CardHeader>
        </a>
      </Card>
    );
  });
};
export const CardLinkTelegram = props => {
  let link = "https://t.me/+RodD0P9qaiY3Zjk0";
  // if (props.categories.filter(x => x.titolo === "Milliondollar2023").length > 0)
  //   link = "https://t.me/+eKJI4uCezCoxMTE8";
  // else if (
  //   props.categories.filter(x => x.titolo === "Milliondollar2022").length > 0
  // )
  //   link = "https://t.me/+oK4_hrzHS-gxNjJk";

  return link.length > 0 ? (
    <Card>
      <a href={link} target="_blank" rel="noreferrer">
        <CardHeader>
          <CardTitle
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <img
              alt="telegram"
              src={require("assets/img/telegram.png")}
              width={100}
              height={100}
            /> */}
            {serviceCardImg("Milliondollar", "telegram")}
            <div>
              <h4>Canale Telegram MILLIONDOLLAR™</h4>
              <h6>Contenuti per VERI traders.</h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  ) : (
    <></>
  );
};

export const CardExpertAdvisor = props => {
  return (
    <>
      {props.categories
        .filter(
          x =>
            (x.descrizioneCategoria === "Milliondollar" ||
              x.descrizioneCategoria === "Thousandollar" ||
              x.descrizioneCategoria === "RoiBooster" ||
              x.descrizioneCategoria === "TheWolfOfIndexes" ||
              x.descrizioneCategoria === "RoiTrading" ||
              x.descrizioneCategoria === "ExpertAdvisor") &&
            x.idProdotto !== 123
        )
        .map(x => (
          <Card key={x.idProdotto}>
            <a
              href={x.risorse.filter(x => x.type === "Exp")[0]?.path}
              target="_blank"
              rel="noreferrer"
            >
              <CardHeader>
                <CardTitle
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {x.descrizioneCategoria === "ExpertAdvisor"
                    ? serviceCardImg("Thousandollar", "ea")
                    : serviceCardImg(x.descrizioneCategoria, "ea")}
                  <div style={{ marginLeft: 40 }}>
                    <h4>Download Expert Advisor {x.titolo}</h4>
                    <h6>
                      Software per la gestione automatica delle operazioni nella
                      piattaforma MT4
                    </h6>
                  </div>
                </CardTitle>
              </CardHeader>
            </a>
          </Card>
        ))}
    </>
  );
};

export const CardDownloadMt4 = () => {
  return (
    <Card>
      <a
        href="https://download.mql5.com/cdn/web/21887/mt4/topfxglobal4setup.exe"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="MT4"
              src={require("assets/img/MT4-Logo.png")}
              width={140}
              height={100}
            />

            <div style={{ marginLeft: 40 }}>
              <h4>Download MetaTrader 4</h4>
              <h6>
                Scarica la piattaforma MT4 messa a disposizione da TOPFX™.
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const CardLibro = () => {
  return (
    <Card>
      <a
        href="https://www.amazon.it/trading-Utilizzare-operare-successo-finanziari/dp/8820390876"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="Amazon Link"
              src={require("assets/img/libro1.png")}
              width={100}
              height={150}
            />

            <div style={{ marginLeft: 40 }}>
              <h4>Copia Digitale del libro "Fare Trading con Excel"</h4>
              <h6>
                Acquista la versione digitale o cartacea del Libro "Fare Trading
                con Excel" di Manuel Trotta, pubblicato con Hoepli.
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const CardLibro2 = () => {
  return (
    <Card>
      <a
        href="https://www.amazon.it/dp/B0BHL3ZZ31/ref=cm_sw_r_awdo_RWSM12521E3KQ495TT5Y_1"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="Amazon Link"
              src={require("assets/img/libro2.png")}
              width={100}
              height={150}
            />

            <div style={{ marginLeft: 40 }}>
              <h4>
                Diventa un Investitore Forex Profittevole con Meno di 12 Minuti
                al Giorno
              </h4>
              <h6>
                Acquista la versione digitale o cartacea del mio nuovo libro.
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const CardLibro3 = () => {
  return (
    <Card>
      <a
        href="https://www.amazon.it/TRADING-SCHIFO-non-come-farlo-ebook/dp/B0BTN34SX3/ref=mp_s_a_1_3?crid=1NJU21HXSUDPZ&keywords=manuel+trotta&qid=1675410876&sprefix=manuel+trotta%2Caps%2C204&sr=8-3"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt="Amazon Link"
              src={require("assets/img/libro3.png")}
              width={100}
              height={150}
            />

            <div style={{ marginLeft: 40 }}>
              <h4>IL TRADING FA SCHIFO [Se non sai come farlo]</h4>
              <h6>Acquista la versione digitale mio nuovo libro.</h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const CardTradingRoom = props => {
  const tdiObj = props.categories.filter(
    x =>
      x.descrizioneCategoria === "Thousandollar" ||
      x.descrizioneCategoria === "Milliondollar"
  )[0];
  const theWolfObj = props.categories.filter(
    x => x.descrizioneCategoria === "TheWolfOfIndexes"
  )[0];
  const arr = [tdiObj, theWolfObj].filter(x => x != undefined);
  return (
    <>
      {arr.map(x => {
        return (
          <div key={x.idProdotto}>
            {x.risorse
              .filter(y => y.type === "Tdr")
              .map(y => (
                <Card key={y.path}>
                  <a href={y.path} target="_blank" rel="noreferrer">
                    <CardHeader>
                      <CardTitle
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {serviceCardImg(x.descrizioneCategoria, "tradingroom")}

                        <div style={{ marginLeft: 40 }}>
                          <h4>TRADING ROOM {y.descrizione}™</h4>
                          <h6>
                            Una serie di TradingRoom e Live nelle quali mostro
                            la mia personale esperienza nei mercati finanziari.
                          </h6>
                        </div>
                      </CardTitle>
                    </CardHeader>
                  </a>
                </Card>
              ))}
          </div>
        );
      })}
    </>
  );
};



export const CardRoibot = props => {
  return props.categories
    .filter(x => x.risorse.filter(x => x.type === "Bot")?.length > 0)
    .map(x => {
      return (
        <Card key={x.idProdotto}>
          <a
            href={x.risorse.filter(x => x.type === "Bot")[0]?.path}
            target="_blank"
            rel="noreferrer"
          >
            <CardHeader>
              <CardTitle style={{ display: "flex" }}>
                <img
                  alt="ROIBOT"
                  src={require("assets/img/bot.png")}
                  width={100}
                  height={100}
                />

                <div style={{ marginLeft: 40 }}>
                  <h4>
                    {x.risorse.filter(x => x.type === "Bot")[0]?.descrizione}™
                  </h4>
                  <h6>
                    Riceverai accesso al servizio di invio segnali automatico
                    direttamente su Smartphone.
                  </h6>
                </div>
              </CardTitle>
            </CardHeader>
          </a>
        </Card>
      );
    });
};

export const CardSuperCalendario = calendarData => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>SUPER CALENDARIO</CardTitle>
      </CardHeader>
      <CardBody>{}</CardBody>
    </Card>
  );
};

export const CardProblemiTecnici = props => {
  const broked = props?.output.filter(
    x => moment(x.timeUpd).add(2, "hours").format("L") !== moment().format("L")
  );
  return broked?.length > 0 ? (
    <Card>
      <CardHeader>
        <CardTitle>Problemi tecnici</CardTitle>
        Si avvisa che per motivi tecnici legati ai server della piattaforma,
        alcuni strumenti possono non essere aggiornati al giorno corrente.
        Strumenti non aggiornati:
      </CardHeader>
      <CardBody>
        {props?.output
          .filter(
            x => moment(x.timeUpd).add(2, "hours") !== moment().format("L")
          )
          .map(x => (
            <span
              key={x.idStrumento}
              style={{
                fontSize: 14,
                color: "white",
                fontWeight: "bold",
                padding: 10,
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {x.idStrumento}
            </span>
          ))}
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};

export const CardContoTeletrade = () => {
  return (
    <a
      href="https://my.teletrade-dj.com/agent_pp.html?agent_pp=26601665"
      target="_blank"
      rel="noreferrer"
    >
      <Card>
        <CardHeader>
          <CardTitle
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              alt="Teletrade"
              src={require("assets/img/Teletrade-Logo.png")}
              width={window.innerWidth <= 768 ? 100 : 100}
              height={window.innerWidth <= 768 ? 50 : 80}
            />
            <div style={{ marginLeft: 40 }}>
              <h4>Apri un conto TELETRADE™</h4>

              <h6>Apri un conto in totale autonomia e sicurezza</h6>
            </div>
          </CardTitle>
        </CardHeader>
      </Card>
    </a>
  );
};

export const CardContoTopFx = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href="https://signup.topfx.com.sc/Registration/Main/Account?dest=live&isSpecAts=true&camp=2136"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Topfx"
              src={require("assets/img/topfx.png")}
              width={window.innerWidth <= 768 ? 160 : 140}
              height={window.innerWidth <= 768 ? 70 : 70}
            />
          </a>

          <div style={{ marginLeft: 40 }}>
            <h4>Apri un conto TOPFX™</h4>

            <h6>Apri un conto in totale autonomia e sicurezza</h6>
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};
export const CardPurpleTrading = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.purple-trading.sc/registration/individual/?step=1-real-it-it&ref=4097"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Purple Trading"
              src={require("assets/img/purletrading.png")}
              width={window.innerWidth <= 768 ? 140 : 120}
              height={window.innerWidth <= 768 ? 50 : 50}
            />
          </a>

          <div style={{ marginLeft: 40 }}>
            <h4>Apri un conto Purple Trading™</h4>

            <h6>Il nostro nuovo broker!</h6>
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

export const CardVTMarkets = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.vtmarkets.net/?cxd=38925_413898&affid"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="VTMARKET"
              src={require("assets/img/vt.png")}
              width={window.innerWidth <= 768 ? 140 : 120}
              height={window.innerWidth <= 768 ? 50 : 50}
            />
          </a>

          <div style={{ marginLeft: 40 }}>
            <h4>Apri un conto VTMARKET™</h4>

            <h6>APRI UN CONTO IN TOTALE AUTONOMIA E SICUREZZA E COLLEGATI ALLE 3 NOSTRE LINEE DI COPYTRADING GRATIS</h6>
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

export const CardBCM = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href="https://secure.bcm-trading.com/links/go/64"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="BCM"
              src={require("assets/img/bcm.png")}
              width={window.innerWidth <= 768 ? 140 : 120}
              height={window.innerWidth <= 768 ? 50 : 50}
            />
          </a>

          <div style={{ marginLeft: 40 }}>
            <h4>Apri un conto BCM™</h4>

            <h6>APRI UN CONTO E RICEVI IMMEDIATAMENTE CAPITALE 20 VOLTE SUPERIORE E COLLEGATI AL NOSTRO COPY GRATIS</h6>
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};