import { setSeenPopup } from 'actions/user/userAction';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'; // Assicurati di importare gli stili

const DashboardPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Controlla se il popup è già stato mostrato
    const hasShownPopup = sessionStorage.getItem('hasShownPopup') === 'true';
    if (!hasShownPopup) {
      setIsOpen(true); // Mostra il popup
      sessionStorage.setItem('hasShownPopup', 'true'); // Salva lo stato in localStorage
      dispatch(setSeenPopup(true)); // Aggiorna anche Redux
    }
  }, [dispatch]);

  const closeModal = () => setIsOpen(false);

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      contentStyle={{
        width: window.outerWidth < 768 ? '80%' : '50%', // Differenziazione dello stile
        textAlign: 'center',
      }}
    >
      <a href="http://www.tradingdalfuturo.com" target="_blank" rel="noopener noreferrer">
        <img
          src={
            window.outerWidth < 768
              ? require('assets/img/popup1-mobile.jpg')
              : require('assets/img/popup1-web.jpg')
          }
          alt="Welcome"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </a>
    </Popup>
  );
};

export default DashboardPopup;
